import en_general from './en/general.json';
import cs_general from './cs/general.json';
import cs_login from './cs/login.json';
import en_login from './en/login.json';
import cs_register from './cs/register.json';
import en_register from './en/register.json';
import cs_passreset from './cs/passreset.json';
import en_passreset from './en/passreset.json';
import en_dashboard from './en/dashboard.json';
import cs_dashboard from './cs/dashboard.json';
import en_profile from './en/profile.json';
import cs_profile from './cs/profile.json';
import en_investments from './en/investments.json';
import cs_investments from './cs/investments.json';
import en_projects from './en/projects.json';
import cs_projects from './cs/projects.json';
import en_clients from './en/clients.json';
import cs_clients from './cs/clients.json';
import en_products from './en/products.json';
import cs_products from './cs/products.json';
import en_settings from './en/settings.json';
import cs_settings from './cs/settings.json';
import en_documents from './en/documents.json';
import cs_documents from './cs/documents.json';
import en_modals from './en/modals.json';
import cs_modals from './cs/modals.json';
import en_posts from './en/posts.json';
import cs_posts from './cs/posts.json';
import en_news from './en/news.json';
import cs_news from './cs/news.json';
import en_validation from './en/validation.json';
import cs_validation from './cs/validation.json';
import en_users from './en/users.json';
import cs_users from './cs/users.json';
import en_auth from './en/auth.json';
import cs_auth from './cs/auth.json';
import en_files from './en/files.json';
import cs_files from './cs/files.json';
import en_tenant_settings from './en/tenant_settings.json';
import cs_tenant_settings from './cs/tenant_settings.json';
import en_product_moves from './en/product_moves.json';
import cs_product_moves from './cs/product_moves.json';
import en_investment_moves from './en/investment_moves.json';
import cs_investment_moves from './cs/investment_moves.json';
import en_investment_move_batches from './en/investment_move_batches.json';
import cs_investment_move_batches from './cs/investment_move_batches.json';
import en_bank_accounts from './en/bank_accounts.json';
import cs_bank_accounts from './cs/bank_accounts.json';
import en_documents_templates from './en/documents_templates.json';
import cs_documents_templates from './cs/documents_templates.json';
import en_withdrawals from './en/withdrawals.json';
import cs_withdrawals from './cs/withdrawals.json';
import en_deposits from './en/deposits.json';
import cs_deposits from './cs/deposits.json';


//export const defaultLocale = 'en'

export const languages = {
    en: {
        general: en_general,
        login: en_login,
        register: en_register,
        passreset: en_passreset,
        dashboard: en_dashboard,
        profile: en_profile,
        investments: en_investments,
        projects: en_projects,
        clients: en_clients,
        products: en_products,
        settings: en_settings,
        documents: en_documents,
        modals: en_modals,
        posts: en_posts,
        news: en_news,
        validation: en_validation,
        users: en_users,
        auth: en_auth,
        files: en_files,
        tenant_settings: en_tenant_settings,
        product_moves: en_product_moves,
        investment_moves: en_investment_moves,
        investment_move_batches: en_investment_move_batches,
        bank_accounts: en_bank_accounts,
        documents_templates: en_documents_templates,
        withdrawals: en_withdrawals,
        deposits: en_deposits,
    },
    cs: {
        general: cs_general,
        login: cs_login,
        register: cs_register,
        passreset: cs_passreset,
        dashboard: cs_dashboard,
        profile: cs_profile,
        investments: cs_investments,
        projects: cs_projects,
        clients: cs_clients,
        products: cs_products,
        settings: cs_settings,
        documents: cs_documents,
        modals: cs_modals,
        posts: cs_posts,
        news: cs_news,
        validation: cs_validation,
        users: cs_users,
        auth: cs_auth,
        files: cs_files,
        tenant_settings: cs_tenant_settings,
        product_moves: cs_product_moves,
        investment_moves: cs_investment_moves,
        investment_move_batches: cs_investment_move_batches,
        bank_accounts: cs_bank_accounts,
        documents_templates: cs_documents_templates,
        withdrawals: cs_withdrawals,
        deposits: cs_deposits,
    }
}
