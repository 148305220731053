import axios from 'axios';
import authHeader from './services/auth-header';
import TenantService from './services/tenant-service';
import TokenService from './services/token-service';



let tenantDomain = TenantService.getDomain();

if(tenantDomain == null) {
  tenantDomain = 'api.crm.techforfund.com';
  //tenantDomain = 'api.incrm.lan';
}

// check if local or not
let tenantUrl = '';
if(tenantDomain.endsWith('incrm.lan')) {
  tenantUrl = 'http://' + tenantDomain;
} else {
  tenantUrl = 'https://' + tenantDomain;
}

let myAxios = axios.create({
  baseURL: tenantUrl + '/api/',
  /* baseURL: 'https://fond-api.api.crm.techforfund.com/api/', */
  headers: authHeader()
});


/* if (tenantDomain == null) {
  tenantDomain = 'api.incrm.lan';
}

let myAxios = axios.create({
  baseURL: 'http://' + tenantDomain + '/api/',
  headers: authHeader()
}); */


let self = this;

// myAxios.interceptors.response.use(function (response) {
//   return response;
// }, async function (error) {
//   // Any status codes that falls outside the range of 2xx cause this function to trigger
//   // Do something with response error
//   if (error.response) {
//     const code = error.response.status;
//     const errorMessage = error.response.data.message;
//     const originalConfig = error.config;
//     if ((code == 401)) {


//       TokenService.removeUser();
//       window.location.reload();
//       return Promise.reject(error);

//     }
//   }
//   return Promise.reject(error);
// });


export default myAxios;
