// Composables
import {createRouter, createWebHistory} from 'vue-router'
import TokenService from '../services/token-service';
import TenantService from '../services/tenant-service';

const routes = [
    {
        path: '/',
        component: () => import('@/layouts/default/Default.vue'),
        redirect: {name: 'Home'},
        children: [
            {
                path: '/dashboard',
                name: 'Home',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import(/* webpackChunkName: "home" */ '@/views/Home.vue'),
                meta: {
                    auth: true
                }
            },
            {
                path: '/profile',
                name: 'Profile',
                component: () => import(/* webpackChunkName: "profile" */ '@/views/Client.vue'),
                meta: {
                    auth: true
                }
            },
            {
                path: '/news',
                name: 'News',
                component: () => import(/* webpackChunkName: "profile" */ '@/views/News.vue'),
                meta: {
                    auth: true
                }
            },
            //{
            //path: '/investments',
            //name: 'Investments',
            //component: () => import(/* webpackChunkName: "investments" */ '@/views/Investments.vue'),
            //},
            {
                path: '/investments/:hash',
                name: 'Investment',
                component: () => import(/* webpackChunkName: "investments" */ '@/views/Investment.vue'),
                meta: {
                    auth: true
                }
            },
            {
                path: '/products/:hash',
                name: 'Product',
                component: () => import(/* webpackChunkName: "product" */ '@/views/Product.vue'),
                meta: {
                    auth: true
                }
            },
            {
                path: '/projects',
                name: 'Projects',
                component: () => import(/* webpackChunkName: "projects" */ '@/views/Projects.vue'),
                meta: {
                    auth: true
                }
            },
            {
                path: '/admin/investments',
                name: 'AdminInvestments',
                beforeEnter: checkIfIsAdmin,
                component: () => import(/* webpackChunkName: "adminInvestments" */ '@/views/Admin/Investments.vue'),
                meta: {
                    auth: true
                }
            },
            {
                path: '/admin/clients/:hash',
                name: 'Client',
                beforeEnter: checkIfIsAdmin,
                component: () => import(/* webpackChunkName: "adminClient" */ '@/views/Client.vue'),
                meta: {
                    auth: true
                }
            },
            {
                path: '/admin/investments/:hash',
                name: 'AdminInvestment',
                beforeEnter: checkIfIsAdmin,
                component: () => import(/* webpackChunkName: "investments" */ '@/views/Investment.vue'),
                meta: {
                    auth: true
                }
            },
            {
                path: '/admin/clients',
                name: 'AdminClients',
                beforeEnter: checkIfIsAdmin,
                component: () => import(/* webpackChunkName: "adminClients" */ '@/views/Admin/Clients.vue'),
                meta: {
                    auth: true
                }
            },
            {
                path: '/admin/users',
                name: 'AdminUsers',
                beforeEnter: checkIfIsAdmin,
                component: () => import(/* webpackChunkName: "adminUsers" */ '@/views/Admin/Users.vue'),
                meta: {
                    auth: true
                }
            },
            {
                path: '/admin/products',
                name: 'AdminProducts',
                beforeEnter: checkIfIsAdmin,
                component: () => import(/* webpackChunkName: "adminProducts" */ '@/views/Admin/Products.vue'),
                meta: {
                    auth: true,
                }
            },
            {
                path: '/admin/deposits',
                name: 'AdminDeposits',
                beforeEnter: checkIfIsAdmin,
                component: () => import(/* webpackChunkName: "adminDeposits" */ '@/views/Admin/Deposits.vue'),
                meta: {
                    auth: true,
                }
            },
            {
                path: '/admin/withdrawals',
                name: 'AdminWithdrawals',
                beforeEnter: checkIfIsAdmin,
                component: () => import(/* webpackChunkName: "adminWithdrawals" */ '@/views/Admin/Withdrawals.vue'),
                meta: {
                    auth: true,
                }
            },
            {
                path: '/admin/batches',
                name: 'AdminBatches',
                beforeEnter: checkIfIsAdmin,
                component: () => import(/* webpackChunkName: "adminBatches" */ '@/views/Admin/Batches.vue'),
                meta: {
                    auth: true,
                }
            },
            {
                path: '/admin/batches/:id',
                name: 'AdminBatchDetail',
                beforeEnter: checkIfIsAdmin,
                component: () => import(/* webpackChunkName: "adminBatchesDetail" */ '@/views/Admin/BatchDetail.vue'),
                meta: {
                    auth: true,
                }
            },
            {
                path: '/admin/document-templates',
                name: 'AdminDocumentTemplates',
                beforeEnter: checkIfIsAdmin,
                component: () => import(/* webpackChunkName: "adminDocumentTemplates" */ '@/views/Admin/DocumentsTemplates.vue'),
                meta: {
                    auth: true,
                }
            }, {
                path: '/admin/document-templates/:id',
                name: 'AdminDocumentTemplatesDetail',
                beforeEnter: checkIfIsAdmin,
                component: () => import(/* webpackChunkName: "adminDocumentTemplateDetail" */ '@/views/Admin/DocumentsTemplateDetail.vue'),
                meta: {
                    auth: true,
                }
            },
            {
                path: '/settings',
                name: 'Settings',
                beforeEnter: checkIfIsAdmin,
                component: () => import(/* webpackChunkName: "settings" */ '@/views/Settings.vue'),
                meta: {
                    auth: true
                }
            },
            {
                path: '/documents/:hash',
                name: 'DocumentDetail',
                component: () => import(/* webpackChunkName: "documentDetail" */ '@/views/DocumentDetail.vue'),
                meta: {
                    auth: true
                }
            },
        ],
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('@/views/Entrance/Login.vue'),
    },
    {
        path: '/register',
        component: () => import('@/views/Entrance/Register.vue'),
    },
    {
        path: '/reset-password',
        component: () => import('@/views/Entrance/PasswordReset.vue'),
        query: true
    },

    {
        path: '/forgotten-password',
        name: 'forgotten-password',
        component: () => import('@/views/Entrance/ForgottenPassword.vue'),
    },

    {
        path: '/:pathMatch(.*)*',
        component: () => {
            let userAuthenticated = false;
            if (userAuthenticated) {
                return import('@/views/App404.vue')
            } else {
                return import('@/views/404.vue')
            }
        },
    },


]

let router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});


router.beforeEach((to, from, next) => {
    if (to.meta.auth) {
        const loggedIn = TokenService.isLoggedIn();
        if (!loggedIn) {
            next({
                path: '/login'
            });
        } else {
            next();
        }
    } else {
        next();
    }
});

router.beforeEach((to, from, next) => {
    document.title = TenantService.getWebTitle();
    next();
});

function checkIfIsAdmin(to, from, next) {
    let isAdmin = TokenService.isAdmin();
    if (isAdmin) {
        next();
    } else {
        TokenService.removeUser();
        next({
            path: '/login'
        });
    }
}

export default router
