import http from "@/http";
class TenantService {

  getDomain() {
    let tenant = this.getTenant();
    if (tenant) {
      return tenant.domain;
    }
    return null;
  }

  getTenantId() {
    let tenant = this.getTenant();
    if (tenant) {
      return tenant.tenant_id;
    }
    return null;
  }

  getDomainWithProtocol() {
    //return 'https://' + this.getDomain() + '/';
    return 'https://' + this.getDomain() + '/';
  }

  getAPIURL(path) {
    return this.getDomainWithProtocol() + 'api/' + (path ? path + '' : '');
  }

  getTenant() {
    return JSON.parse(localStorage.getItem('tenant'));
  }

  setTenantInfo(tenant) {
    http.baseURL = tenant.domain;
    localStorage.setItem('tenant', JSON.stringify(tenant));
  }

  setSettings(settings) {
    localStorage.setItem('tenant_settings', JSON.stringify(settings));
  }

  getSettings() {
    return JSON.parse(localStorage.getItem('tenant_settings'));
  }

  getTheme() {
    const settings = this.getSettings();
    return settings?.theme ? settings.theme : "Default";
  }

  getWebTitle() {
    const settings = this.getSettings();
    return settings?.web_title ? settings.web_title : "IN CRM";
  }

  getThemeDefaultColor(value) {
    let settings = this.getSettings();
    let settingsDefaults = settings.theme_colors;

    return settingsDefaults && settingsDefaults.hasOwnProperty(value) ? settingsDefaults[value] : null;
  }



  getLogoUrl() {
    const settings = this.getSettings();
    return settings?.logo_url ? settings.logo_url : null;
  }


}

export default new TenantService();
