import TokenService from './token-service';

export default function authHeader() {

  let token = TokenService.getToken();
  let headers = {
    'Accept': '*/*',
    'Content-Type': 'application/json',
  };

  if(token) {
    headers['Authorization'] = 'Bearer ' + token;
  }

  return headers;

}
