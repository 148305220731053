/**
 * main.js
 *
 * Bootstraps Vuetify and other plugins then mounts the App`
 */


// Components
import App from './App.vue';

// Composables
import { createApp } from 'vue';

// Plugins
import { registerPlugins } from '@/plugins';
import { createI18n } from 'vue-i18n';
import VueGoodTablePlugin from 'vue-good-table-next';
import { languages } from './locales/index.js';
import ToastPlugin from 'vue-toast-notification';
import DropZone from 'dropzone-vue';
import VueNumberFormat from 'vue-number-format';
import { QuillEditor } from '@vueup/vue-quill';
import Multiselect from '@vueform/multiselect'

// import the styles
import 'vue-good-table-next/dist/vue-good-table-next.css'
// Import one of the available themes
//import 'vue-toast-notification/dist/theme-default.css';
import 'vue-toast-notification/dist/theme-sugar.css';
import 'dropzone-vue/dist/dropzone-vue.common.css';
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import '@vueup/vue-quill/dist/vue-quill.bubble.css';
import '@vueform/multiselect/themes/default.css';

import { VSkeletonLoader } from 'vuetify/labs/VSkeletonLoader';

const app = createApp(App);
const i18n = createI18n({
  locale: 'cs',
  fallbackLocale: 'en',
  messages: {
    cs: languages.cs,
    en: languages.en,
  }
});



registerPlugins(app);
app.use(i18n);
app.use(VueGoodTablePlugin);
app.use(ToastPlugin, {
  duration: 5000
});
app.use(VueNumberFormat, {prefix: '', decimal: ',', thousand: ' ', precision: 2})
app.component('dropzone', DropZone);
app.component('QuillEditor', QuillEditor);
app.component('v-skeleton-loader', VSkeletonLoader);
app.component('multi-select', Multiselect);

import PageTitle from './components/PageTitle.vue';
app.component('PageTitle', PageTitle);

app.mount('#app');
