<template>
  <v-template v-if="loaded">
    <router-view/>
  </v-template>
  <v-template v-if="!loaded">
    <v-progress-linear indeterminate color="gray"></v-progress-linear>
  </v-template>
</template>

<script>
import {useTheme} from 'vuetify';
import http from '@/http';
import TenantService from './services/tenant-service';
import TokenService from '@/services/token-service';
import {Chart} from 'chart.js';

export default {
  data() {
    return {
      loaded: false,
    };
  },

  mounted() {
    let self = this;
    const theme = useTheme();

    this.checkTenantName(function () {
      self.initHttp();
      self.getSettings(function () {
        self.loaded = true;
      });
      if (TokenService.isLoggedIn()) {
        self.checkTtl();
      }
    });
  },

  methods: {
    initHttp() {
      let self = this;

      http.interceptors.response.use(
          function (response) {
            if (response.data.message) {
              let preparedMessage = response.data.message;
              self.$toast.success(self.$t(preparedMessage));
            }

            return response;
          },
          async function (error) {
            // Any status codes that falls outside the range of 2xx cause this function to trigger
            // Do something with response error
            if (error.response) {
              const code = error.response.status;
              const errorMessage = error.response.data.message;
              const originalConfig = error.config;
              if (code == 401) {
                // TokenService.removeUser();
                // window.location.reload();
                return Promise.reject(error);
              } else {
                if (error.response.data.message) {
                  let preparedMessage = error.response.data.message;
                  self.$toast.error(self.$t(preparedMessage));
                }
              }
            }
            return Promise.reject(error);
          }
      );
    },

    checkTtl: function () {
      let self = this;
      setInterval(function () {
        if (TokenService.checkTokenIsExpired()) {
          // odhlasim uzivatele
          TokenService.removeUser();
          window.location.reload();
        }
      }, 1000);
    },

    checkTenantName: function (callback) {
      let body = {
        domain: window.location.origin,
      };
      http.post('app', body).then((response) => {
        let data = response.data;
        TenantService.setTenantInfo({
          name: data.tenant_name,
          domain: data.tenant_api_url,
          tenant_id: data.tenant_id,
        });
        if (typeof callback === 'function') {
          callback();
        }
      });
    },

    getSettings(callback) {
      let self = this;
      let url = 'tenant-settings';
      if (!TokenService.isLoggedIn()) {
        url = 'public-tenant-settings';
      }

      http.get(TenantService.getAPIURL(url)).then((response) => {

        let tenantId = TenantService.getTenantId();
        let settings = response.data;
        if (tenantId && (tenantId === '2c7d4a5d-93f3-4a0a-bb07-38ff6310bde7' || tenantId === 'b75f275d-e044-4a3c-8bb5-5d7c44c1aa41' || tenantId === 'bf4eea22-efef-4fdf-bde7-f6e211e15c48')) {
          settings.display_deposits = true;
          settings.display_withdrawals = true;
          settings.display_batches = true;
        }


        TenantService.setSettings(settings);
        // self.$vuetify.theme.name = self.settings.theme ? self.settings.theme : "Default";
        self.$vuetify.theme.name = TenantService.getTheme();
        /*  Chart.defaults.backgroundColor = TenantService.getThemeDefaultColor('chart_background');
       Chart.defaults.borderColor = TenantService.getThemeDefaultColor('chart_border');
       Chart.defaults.color = TenantService.getThemeDefaultColor('chart_color'); */
        self.$vuetify.theme.logo_url = TenantService.getLogoUrl();
        document.title = TenantService.getWebTitle();
        if (typeof callback == 'function') callback();
      });
    },
  },
};
</script>
