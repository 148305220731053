/**
 * plugins/vuetify.js
 *
 * Framework documentation: https://vuetifyjs.com`
 */

// Styles
import '@mdi/font/css/materialdesignicons.css';
import 'vuetify/styles';
import '../assets/styles.css';

// Composables
import { createVuetify } from 'vuetify'

// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
export default createVuetify({
  theme: {
    defaultTheme: 'Default',
    options: {
      customProperties: true
    },
    themes: {
      dark: false,
      light: false,
      Default: {
        colors: {
          background: '#F9F9F9',
          surface: '#FFFFFF',
          primary: '#65bfb2',
          'primary-darken-1': '#3700B3',
          secondary: '#03DAC6',
          'secondary-darken-1': '#018786',
          error: '#B00020',
          info: '#2196F3',
          success: '#4CAF50',
          warning: '#FB8C00',
        },
      },
      /* light: {
        colors: {
          primary: "#21CFF3",
          accent: "#FF4081",
          secondary: "#21dc79",
          success: "#86af3f",
          info: "#f34fc6",
          warning: "#FB8C00",
          error: "#FF5252"
        },
      }, */
      Green: {
        colors: {
          background: '#F9F9F9',
          surface: '#FFFFFF',
          primary: '#65bf6d',
          'primary-darken-1': '#3700B3',
          secondary: '#03DAC6',
          'secondary-darken-1': '#018786',
          error: '#B00020',
          info: '#2196F3',
          success: '#4CAF50',
          warning: '#FB8C00',
        },
      },
      Khaki: {
        colors: {
          background: '#F9F9F9',
          surface: '#FFFFFF',
          primary: '#babf65',
          'primary-darken-1': '#3700B3',
          secondary: '#03DAC6',
          'secondary-darken-1': '#018786',
          error: '#B00020',
          info: '#2196F3',
          success: '#4CAF50',
          warning: '#FB8C00',
        },
      },
      Venetian: {
        colors: {
          background: '#F9F9F9',
          surface: '#FFFFFF',
          primary: '#bf65b3',
          'primary-darken-1': '#3700B3',
          secondary: '#03DAC6',
          'secondary-darken-1': '#018786',
          error: '#B00020',
          info: '#2196F3',
          success: '#4CAF50',
          warning: '#FB8C00',
        },
      },
      Deer: {
        colors: {
          background: '#F9F9F9',
          surface: '#FFFFFF',
          primary: '#bf7e65',
          'primary-darken-1': '#3700B3',
          secondary: '#03DAC6',
          'secondary-darken-1': '#018786',
          error: '#B00020',
          info: '#2196F3',
          success: '#4CAF50',
          warning: '#FB8C00',
        },
      },
      JungleMist: {
        //options: { tenant_id: "5ecbb039-8107-4d63-9cfa-0bd441c3760a" },
        colors: {
          background: '#F9F9F9',
          surface: '#FFFFFF',
          primary: '#B1CDD2',
          'primary-darken-1': '#3700B3',
          secondary: '#bf7e65',
          'secondary-darken-1': '#018786',
          error: '#B00020',
          info: '#2196F3',
          success: '#4CAF50',
          warning: '#FB8C00',
        },
      },
      DarkSkyBlue: {
        colors: {
          background: '#F9F9F9',
          surface: '#FFFFFF',
          primary: '#4284F4',
          'primary-darken-1': '#3700B3',
          secondary: '#bf7e65',
          'secondary-darken-1': '#018786',
          error: '#B00020',
          info: '#2196F3',
          success: '#4CAF50',
          warning: '#FB8C00',
        },
      },
    },
  },
})
